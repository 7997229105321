import moment, {Moment} from 'moment'
import {DateFilterToggle} from '../types'

export const dateFormat = 'DD/MM/YYYY'
export const monthYearFormat = 'MMMM YYYY'
export const monthShortYearFormat = 'MMM YY'
export const monthShortFormat = 'MMM'
export const dateTimeFormat = 'DD/MM/YYYY h:mm A'
export const fullDateTimeFormat = 'DD/MM/YYYY h:mm:ss A'

export const monthsInRange = (start: Moment, end: Moment, monthFormat?: string): string[] => {
	const interim = start.clone()
	const result = []

	while (end > interim || interim.format('M') === end.format('M')) {
		result.push(interim.format(monthFormat ?? monthShortYearFormat))
		interim.add(1, 'month')
	}

	return result
}

const isFirstHalfOfYear = () => moment().month() < 6

export const thisYear = () => moment().format('YYYY')
export const lastYear = () => moment().add(-1, 'year').format('YYYY')
export const nextYear = () => moment().add(1, 'year').format('YYYY')
export const h1Start = () => moment().startOf('year').startOf('month').add(-1, 'month')
export const h1End = () => moment().startOf('year').endOf('month').add(4, 'month')
export const h2Start = () => moment().startOf('year').startOf('month').add(5, 'month')
export const h2End = () => moment().endOf('year').endOf('month').add(-1, 'month')

export const getDefaultFrom = () => isFirstHalfOfYear() ? h1Start() : h2Start()
export const getDefaultTo = () => isFirstHalfOfYear() ? h1End() : h2End()

const h1ThisYear: DateFilterToggle = {
	label: `H1 ${thisYear()}`,
	dateFrom: h1Start(),
	dateTo: h1End()
}

const h2ThisYear: DateFilterToggle = {
	label: `H2 ${thisYear()}`,
	dateFrom: h2Start(),
	dateTo: h2End()
}

const h1LastYear: DateFilterToggle = {
	label: `H1 ${lastYear()}`,
	dateFrom: moment().startOf('year').startOf('month').add(-1, 'year').add(-1, 'month'),
	dateTo: moment().startOf('year').endOf('month').add(-1, 'year').add(4, 'month')
}

const h2LastYear: DateFilterToggle = {
	label: `H2 ${lastYear()}`,
	dateFrom: moment().startOf('year').startOf('month').add(-1, 'year').add(5, 'month'),
	dateTo: moment().endOf('year').endOf('month').add(-1, 'year').add(-1, 'month')
}

const h1NextYear: DateFilterToggle = {
	label: `H1 ${nextYear()}`,
	dateFrom: moment().startOf('year').startOf('month').add(1, 'year').add(-1, 'month'),
	dateTo: moment().startOf('year').endOf('month').add(1, 'year').add(4, 'month')
}

const h2NextYear: DateFilterToggle = {
	label: `H2 ${nextYear()}`,
	dateFrom: moment().startOf('year').startOf('month').add(1, 'year').add(5, 'month'),
	dateTo: moment().endOf('year').endOf('month').add(1, 'year').add(-1, 'month')
}

export const dateFilterToggles = () => {
	const range = [
		h1NextYear,
		h2NextYear,
		h1ThisYear,
		h2ThisYear,
		h1LastYear,
		h2LastYear
	]

	let start = 2

	if (moment() > h2ThisYear.dateTo) {
		start = 0
	}

	return range.slice(start, 4)
}

export const getDefaultDateFilterToggle = () => {
	if (isFirstHalfOfYear()) {
		return h1ThisYear
	}

	if (moment() > h2ThisYear.dateTo) {
		return h1NextYear
	}

	return h2ThisYear
}

export const isValidDate = (value : string) => moment(value, fullDateTimeFormat, true).isValid()

export const nowDateTime =  moment().format(fullDateTimeFormat)

const minuteMilliseconds = 60 * 1000
const hourMilliseconds = 60 * minuteMilliseconds
const dayMilliseconds = 24 * hourMilliseconds

export const getDuration = (milliseconds: number) => {

	const days = Math.floor(milliseconds / dayMilliseconds)
	milliseconds -= days * dayMilliseconds;
	const hours = Math.floor(milliseconds / hourMilliseconds)
	milliseconds -= hours * hourMilliseconds;
	const minutes = Math.floor(milliseconds / minuteMilliseconds)
	milliseconds -= minutes * minuteMilliseconds;
	const seconds = Math.floor(milliseconds / 1000)

	return (
		(days ? `${days} days ` : '') +
		(hours ? `${hours} hours ` : '') +
		(minutes ? `${minutes} minutes ` : '') +
		(seconds ? `${seconds} seconds ` : '')
	).trim()
}