import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import TrustPilotValueCard from './Common/TrustPilotValueCard/TrustPilotValueCard'

const RefundTimeSummary = () => {
	const {getAverageRefundHandleTime} = usePulseApi()
	const value = useApiValue(filterState, getAverageRefundHandleTime, 0)

	return (
		<TrustPilotValueCard
			value={`${value.data}h`}
			header="Average Refund Time"
			tooltipText="This refers to the average duration taken to process refunds for customers who opt for Refund Protect.
				This metric encompasses both AI-driven automatic instant refunds and manual refunds processed by the Customer Experience team.
				This does not include the time taken to process the payment nor the time taken by the customer to respond."
			isLoading={value.isLoading}
		/>
	)
}

export default RefundTimeSummary
