import React from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import H2 from './components/Common/Headers/H2'
import PageAnalyticsView from './components/PageAnalyticsView'

const PulsePageAnalyticsView = () => {

	return (
		<DashboardPageLayout>
			<section className="
				max-w-6xl mb-8 px-4 relative w-full
				lg:mx-auto lg:px-10"
			>
				<H2>User History</H2>
				<PageAnalyticsView/>
			</section>
		</DashboardPageLayout>
	)
}

export default PulsePageAnalyticsView
