import {ReactElement} from 'react'
import {RecoilValueReadOnly} from 'recoil'
import {PulseFilter} from './pulse'

export interface SalesRegion {
	name: string
	regionId: number
}

export interface UserDetail {
	coreCurrencyCode?: string
	isProtectGroup?: boolean
	memberId?: number
	memberName?: string
	userName?: string
	name?: string
}

export interface TrustPilotReview {
	reviewTitle: string
	reviewBody: string
	reviewStars: number
}

export interface TableRow {
	customerName: string
	isCancelled: boolean
	isInsured: boolean
	member: string
	platformTransactionId: number
	region: string
	transactionConsumerValue: number
	transactionCurrency: string
	transactionDate: string
	transactionEndDate: string
	transactionId: number
	transactionReferenceId: string
	transactionValue: number
}

export interface TransactionTableData {
	rows: TableRow[]
}

export interface ExportData {
	csvElement?: ReactElement
	isLoading: boolean

	exportCSV(): Promise<void>

	exportXLSX(): Promise<void>
}

export interface PulseTableDataModel {
	labels: string[],
	chartData?: Record<number, number[]>
}

export interface PulseMapDataModel {
	countries?: Record<string, PulseMapDataCountry>,
	upsellMember?: boolean
}

export interface PulseMapDataCountry {
	countryName?: string
	refundProtectValue?: number
	grossBookingValue?: number
	yourRevenue?: number
	refundProtectVolume?: number
	grossBookingVolume?: number
	revenuePerDollar?: number
	averageConversionRate?: number
}

export interface PulseChartDataModel {
	countries?: PulseChartDataCountry[]
}

export interface PulseChartDataCountry {
	countryName?: string
	chartData?: Record<number, number>
}

export interface PulsePartnersRevenueTableDataModel {
	count?: number
	members?: PulsePartnersRevenueTableDataMember[]
}

export interface PulsePartnersRevenueTableDataMember {
	approvedRefundsValue?: number
	integrationType?: string
	lossRatio?: number
	memberName?: string
	pricingModel?: string
	profitValue?: number
	protectionValue?: number
	refundProtectValue?: number
}

export interface PulseVerticalsRevenueTableDataModel {
	verticals?: PulseVerticalsRevenueTableDataVertical[]
}

export interface PulseVerticalsRevenueTableDataVertical {
	approvedRefundsValue?: number
	lossRatio?: number
	profitValue?: number
	protectionValue?: number
	refundProtectValue?: number
	verticalName?: string
}

export interface SupportDetail {
	commercialName?: string
	commercialEmail?: string
	commercialPhone?: string
	activeFromDate?: string
	showDataAvailabilityMessage?: boolean
	isFacilitator?: boolean
}

export interface FacilitatorDetailDataModel
{
	clients?: FacilitatorDetailMemberDataModel[]
}

export interface FacilitatorDetailMemberDataModel
{
	memberName?: string
	memberId?: number
	clientRevenue?: number
	rates?: FacilitatorDetailRateDataModel[]
}

export interface FacilitatorDetailRateDataModel
{
	productName?: string
	ratePercentage?: number
}

export interface Product {
	code?: string
	id?: number
	name?: string
}

export interface Vertical {
	id?: number
	name?: string
}

export interface PulsePageAnalyticsDataModel {
	sessionDateTimeUtc?: string
	dateTimeEndedUtc?: string
	memberName?: string
	userName?: string
}

export interface PulsePageAnalyticsTableData {
	analytics?: PulsePageAnalyticsDataModel[],
	count?: number
}

export interface FilterStateProps {
	filterState?: RecoilValueReadOnly<PulseFilter>
}

export interface PulseValueBandsChartData {
	chartData?: PulseValueBandsDataModel[]
}

export interface PulseValueBandsDataModel {
	conversionRate?: number
	grossBookingVolume?: number
	rangeEnd?: number
	rangeStart?: number
}

export interface PulseProtectionPeriodBandsChartData {
	chartData?: PulseProtectionPeriodBandsDataModel[]
}

export interface PulseProtectionPeriodBandsDataModel {
	conversionRate?: number
	grossBookingVolume?: number
	protectionPeriod?: protectionPeriodBand
}

export enum protectionPeriodBand {
	From0WeeksTo1Week,
	From1WeekTo2Weeks,
	From2WeeksTo1Month,
	From1MonthTo3Months,
	From3MonthsTo6Months,
	From6MonthsTo1Year,
	From1YearPlus
}

export interface PulseRefundPeriodBandsChartData {
	chartData?: PulseRefundPeriodBandsDataModel[]
}

export interface PulseRefundPeriodBandsDataModel {
	refundPeriod?: refundPeriodBand
	refundVolume?: number
}

export enum refundPeriodBand {
	From6MonthsPlusPrior,
	From6MonthsTo3MonthsPrior,
	From3MonthsTo1MonthPrior,
	From1MonthTo2WeeksPrior,
	From2WeeksTo1WeekPrior,
	From1WeekTo4DaysPrior,
	From3DaysPrior,
	From2DaysPrior,
	From1DayPrior,
	ZeroDays,
	From1DayTo1Week,
	From1WeekTo1Month,
	From1MonthTo3Months,
	From3MonthsTo6Months,
	From6MonthsPlus
}

export const protectionPeriodName: Record<protectionPeriodBand, string> = {
	[protectionPeriodBand.From0WeeksTo1Week]: '0 - 1 week',
	[protectionPeriodBand.From1WeekTo2Weeks]: '1 - 2 weeks',
	[protectionPeriodBand.From2WeeksTo1Month]: '2 weeks - 1 month',
	[protectionPeriodBand.From1MonthTo3Months]: '1 - 3 months',
	[protectionPeriodBand.From3MonthsTo6Months]: '3 - 6 months',
	[protectionPeriodBand.From6MonthsTo1Year]: '6 months - 1 year',
	[protectionPeriodBand.From1YearPlus]: '1+ years',
}

export const refundPeriodName: Record<refundPeriodBand, string> = {
	[refundPeriodBand.From6MonthsPlusPrior]: '+6 months (prior)',
	[refundPeriodBand.From6MonthsTo3MonthsPrior]: '6 - 3 months (prior)',
	[refundPeriodBand.From3MonthsTo1MonthPrior]: '3 - 1 month (prior)',
	[refundPeriodBand.From1MonthTo2WeeksPrior]: '1 month - 2 weeks (prior)',
	[refundPeriodBand.From2WeeksTo1WeekPrior]: '2 - 1 week (prior)',
	[refundPeriodBand.From1WeekTo4DaysPrior]: '1 week - 4 days (prior)',
	[refundPeriodBand.From3DaysPrior]: '3 days (prior)',
	[refundPeriodBand.From2DaysPrior]: '2 days (prior)',
	[refundPeriodBand.From1DayPrior]: '1 day (prior)',
	[refundPeriodBand.ZeroDays]: '0 day (day of the booking)',
	[refundPeriodBand.From1DayTo1Week]: '1 day - 1 week (after)',
	[refundPeriodBand.From1WeekTo1Month]: '1 week - 1 month (after)',
	[refundPeriodBand.From1MonthTo3Months]: '1 - 3 months (after)',
	[refundPeriodBand.From3MonthsTo6Months]: '3 - 6 months (after)',
	[refundPeriodBand.From6MonthsPlus]: '6+ months (after)'
}