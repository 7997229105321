import React from 'react'
import classNames from 'classnames'
import {dateRange} from '../../../../../types/pulse'
import moment from 'moment/moment'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {dateFromState, dateToState, selectedDateRangeState} from '../../../../../state/pulse-filters'
import {calendarDateFromState, calendarDateToState} from '../../../../../state/pulse'

const YearFilter = (props: {absolute: boolean, index: number}) => {
	const currentDate = moment().add(props.index, 'year')
	const date = useRecoilValue(selectedDateRangeState)
	const [dateFrom, setDateFrom] = useRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const setCalendarDateFrom = useSetRecoilState(calendarDateFromState)
	const setCalendarDateTo = useSetRecoilState(calendarDateToState)
	const isSelection = date === dateRange.Year &&
		dateFrom?.get('year') === currentDate.get('year')

	const handleYearClick = () => {
		const from = moment().add(props.index, 'year').startOf('year')
		const to = moment().add(props.index, 'year').endOf('year')

		setCalendarDateFrom(from.toDate())
		setCalendarDateTo(to.toDate())
		setDateFrom(from)
		setDateTo(to)
	}

	return (
		<div
			className={classNames('left-4 mb-2 mr-2 px-3 py-[2px] top-2',
				'cursor-pointer',
				'border border-pulse-icon-gray rounded-full',
				'text-nowrap text-sm tracking-[1px] uppercase',
				'hover:bg-pulse-button-blue',
				'lg:left-0 lg:mb-0 lg:relative lg:top-0', {
					'bg-pulse-button-blue': isSelection,
					'bg-white': !isSelection,
					'absolute': props.absolute && isSelection
				})}
			onClick={handleYearClick}
		>
			<span className="align-middle leading-5">
				{currentDate.format('YYYY')}
			</span>
		</div>
	)
}

export default YearFilter
