import {usePulseApi} from '../../../hooks/api/pulse'
import {
	dashboardRestrictionState,
	partnersRevenueTableFilterState
} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import {formatCurrency} from '../../../utils/currency'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {dashboardRestriction} from '../../../types/pulse'

export default function useExportPartnersRevenueData(): ExportData {
	const {
		getPartnersRevenueTableData
	} = usePulseApi()
	const filter = useRecoilValue(partnersRevenueTableFilterState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	const getData = async (): Promise<any> => {
		const result = await getPartnersRevenueTableData({
			...filter,
			pageSize: 0
		})
		const data = []

		if (!result.members) {
			return []
		}

		for (let i = 0; i < result.members.length; i++) {
			const row = result.members[i]

			data.push({
				'Partner': row.memberName,
				'Pricing Model': row.pricingModel,
				'PG Revenue': formatCurrency(coreCurrency, row.protectionValue || 0, 0, 0),
				'RP Sales': formatCurrency(coreCurrency, row.refundProtectValue || 0, 0, 0),
				'Partner\'s Revenue': formatCurrency(coreCurrency, row.profitValue || 0, 0, 0),
				'Paid Refunds': dashboardRestrictions === dashboardRestriction.Leadership
					? formatCurrency(coreCurrency, row.approvedRefundsValue || 0, 0, 0)
					: null,
				'Loss Ratio': dashboardRestrictions === dashboardRestriction.Leadership
					? row.lossRatio + '%'
					: null
			})
		}

		return data
	}
	const getFileName = () => `Partners-${moment().toISOString()}`
	const getHeaders = () => {
		const headers = [
			'Partner',
			'Pricing Model',
			'PG Revenue',
			'RP Sales',
			'Partner\'s Revenue'
		]

		if (dashboardRestrictions === dashboardRestriction.Leadership) {
			headers.push(
				'Paid Refunds',
				'Loss Ratio'
			)
		}

		return headers
	}

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
