import React, {useEffect, useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {selectedRegionIdState} from '../../../../../state/pulse-filters'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'
import {salesRegionsState} from '../../../../../state/pulse'
import {SalesRegion} from '../../../../../types/pulse-data'

const RegionFilter = () => {
	const salesRegions = useRecoilValue(salesRegionsState)
	const [regionId, setRegionId] = useRecoilState(selectedRegionIdState)
	const [filteredRegions, setFilteredRegions] = useState<SalesRegion[]>([])

	useEffect(() => {
		setFilteredRegions(salesRegions.filter(x => x.regionId === regionId))
	}, [regionId, salesRegions])

	return (
		<Listbox value={regionId} onChange={setRegionId}>
			<ListboxButton>
				<div className="
					mb-2 pl-3 pr-2 py-[2px]
					bg-white
					border border-pulse-icon-gray rounded-full
					text-nowrap text-sm tracking-[1px] uppercase
					cursor-pointer
					hover:bg-pulse-button-blue
					lg:mb-0"
				>
					{!regionId && (<span className="leading-5">Region</span>)}
					{filteredRegions && filteredRegions.length > 0 && (
						<span className="leading-5">{filteredRegions[0].name}</span>
					)}
					<span className="
						align-middle
						leading-5
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				className="
					h-full min-w-[var(--button-width)] mt-[5px] py-2 z-50
					bg-white
					text-sm uppercase
					border border-pulse-popup-border-gray rounded-lg
					lg:w-auto
					[--anchor-max-height:12rem]"
				anchor="bottom start"
			>
				<ListboxOption
					key={0}
					value={null}
					className="
						px-6 py-1
						cursor-pointer
						data-[focus]:bg-pulse-button-blue"
				>
					All
				</ListboxOption>
				{salesRegions.map((salesRegion) => (
					<ListboxOption
						key={salesRegion.regionId}
						value={salesRegion.regionId}
						className="
							max-w-48 px-6 py-1
							cursor-pointer
							data-[focus]:bg-pulse-button-blue"
					>
						{salesRegion.name}
					</ListboxOption>
				))}
			</ListboxOptions>
		</Listbox>
	)
}

export default RegionFilter
