import {usePulseApi} from '../../../hooks/api/pulse'
import {partnersRevenueTableFilterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {
	ExportData,
	PulseValueBandsDataModel
} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import {formatCurrency} from '../../../utils/currency'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'

export default function useExportValueBandData(): ExportData {
	const {
		getValueBandsChartData
	} = usePulseApi()
	const filter = useRecoilValue(partnersRevenueTableFilterState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	const getData = async (): Promise<any> => {
		const result = await getValueBandsChartData({
			...filter,
			pageSize: 0
		})
		const data = []

		if (!result.chartData) {
			return []
		}

		for (let i = 0; i < result.chartData.length; i++) {
			const row = result.chartData[i]

			data.push({
				'Band': getTitle(row),
				'Conversion Rate': `${row.conversionRate}%`,
				'Gross Booking Volume': row.grossBookingVolume
			})
		}

		return data
	}
	const getFileName = () => `ValueBands-${moment().toISOString()}`
	const getHeaders = () => [
		'Band',
		'Conversion Rate',
		'Gross Booking Volume'
	]

	const getTitle = (row: PulseValueBandsDataModel) => {
		if (!row.rangeStart) {
			return `${formatCurrency(coreCurrency, 0)} - ${formatCurrency(coreCurrency, row.rangeEnd || 0)}`
		}

		if (!row.rangeEnd) {
			return `${formatCurrency(coreCurrency, row.rangeStart || 0)}+`
		}

		return `${formatCurrency(coreCurrency, row.rangeStart)} - ${formatCurrency(coreCurrency, row.rangeEnd)}`
	}

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
