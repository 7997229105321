import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {formatNumber} from '../../../utils/number'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {protectionPeriodName} from '../../../types/pulse-data'
import {pulseColorGray, pulseColorGreen} from '../../../types/pulse'

export default function useGetProtectionPeriodBandBarChartData() {
	const {getProtectionPeriodBandsChartData} = usePulseApi()
	const value = useApiValue(filterState, getProtectionPeriodBandsChartData, {})

	const getOptions = () => {
		const options: ChartOptions<'bar'> = {
			indexAxis: 'y',
			plugins: {
				legend: {
					display: true,
					labels: {
						usePointStyle: true,
						padding: 30
					},
					position: 'top'
				},
				tooltip: {
					bodySpacing: 8,
					boxPadding: 4,
					callbacks: {
						label: (tooltip: TooltipItem<'bar'>) => {
							if (tooltip.dataset.label === 'Conversion Rate') {
								return `${tooltip.dataset.label}: ${tooltip.raw}%`
							}

							return `${tooltip.dataset.label}: ${formatNumber(tooltip.raw?.toString() || '', 0)}`
						}
					},
					enabled: true,
					mode: 'index',
					padding: 16,
					titleMarginBottom: 8
				}
			},
			scales: {
				x1: {
					display: true,
					position: 'top',
					type: 'linear'
				},
				x2: {
					display: true,
					grid: {
						display: false
					},
					min: 0,
					position: 'bottom',
					ticks: {
						callback: function (value) {
							return `${value}%`
						}
					}
				},
				y: {
					display: true,
					grid: {
						display: false
					}
				}
			}
		}

		return options
	}

	const getLabels = () =>
		(value.data?.chartData && value.data.chartData.map(band =>
			protectionPeriodName[band.protectionPeriod || 0]
		)) || []

	const getDatasets = () => {
		const datasets: ChartDataset<'bar'>[] = []

		if (!value.data?.chartData) {
			return datasets
		}

		const grossBookingVolumeData: number[] = []
		const conversionRateData: number[] = []

		for (let i = 0; i < value.data.chartData.length; i++) {
			const band = value.data.chartData[i]

			grossBookingVolumeData.push(band.grossBookingVolume || 0)
			conversionRateData.push(band.conversionRate || 0)
		}

		datasets.push({
			backgroundColor: pulseColorGray,
			data: grossBookingVolumeData,
			label: 'Gross Booking Volume',
			xAxisID: 'x1'
		}, {
			backgroundColor: pulseColorGreen,
			data: conversionRateData,
			label: 'Conversion Rate',
			xAxisID: 'x2'
		})

		return datasets
	}

	return {
		getDatasets,
		getLabels,
		getOptions,
		isLoading: value.isLoading
	}
}
