import React from 'react'
import classNames from 'classnames'
import {dateRange} from '../../../../../types/pulse'
import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState
} from 'recoil'
import {
	dateFromState,
	dateMonthState,
	dateToState,
	selectedDateRangeState
} from '../../../../../state/pulse-filters'
import {
	calendarDateFromState,
	calendarDateToState
} from '../../../../../state/pulse'
import moment from 'moment/moment'

const MonthsFilter = () => {
	const date = useRecoilValue(selectedDateRangeState)
	const setDateFrom = useSetRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const setCalendarDateFrom = useSetRecoilState(calendarDateFromState)
	const setCalendarDateTo = useSetRecoilState(calendarDateToState)
	const [dateMonth, setDateMonth] = useRecoilState(dateMonthState)
	const startOfMonth = moment().clone().startOf('month')
	const startOfDay = moment().clone().startOf('day')
	const isMonthRange = date === dateRange.Month || date === dateRange.MonthToDate

	const handleMonthClick = () => {
		const from = dateMonth.clone().startOf('month')
		let to = dateMonth.clone().endOf('month')

		if (from.isSame(startOfMonth)) {
			to = startOfDay.clone()
		}

		setCalendarDateFrom(from.toDate())
		setCalendarDateTo(to.toDate())
		setDateFrom(from)
		setDateTo(to)
	}

	const handlePreviousMonthClick = () => {
		if (!isMonthRange) {
			handleMonthClick()
			return
		}

		const current = dateMonth.clone().add(-1, 'month')
		const from = current.clone().startOf('month')
		let to = current.clone().endOf('month')

		if (from.isSame(startOfMonth)) {
			to = startOfDay.clone()
		}

		setCalendarDateFrom(from.toDate())
		setCalendarDateTo(to.toDate())
		setDateFrom(from)
		setDateTo(to)
		setDateMonth(current)
	}

	const handleNextMonthClick = () => {
		if (!isMonthRange) {
			handleMonthClick()
			return
		}

		const current = dateMonth.clone().add(1, 'month')
		const from = current.clone().startOf('month')
		let to = current.clone().endOf('month')

		if (from.isSame(startOfMonth)) {
			to = startOfDay.clone()
		}

		setCalendarDateFrom(from.toDate())
		setCalendarDateTo(to.toDate())
		setDateFrom(from)
		setDateTo(to)
		setDateMonth(current)
	}

	return (
		<div className={classNames('left-4 mb-2 mr-2 py-[2px] top-2',
			'cursor-pointer',
			'rounded-full border border-pulse-icon-gray',
			'text-sm text-nowrap tracking-[1px] uppercase',
			'hover:bg-pulse-button-blue',
			'lg:left-0 lg:mb-0 lg:relative lg:top-0', {
				'absolute bg-pulse-button-blue': isMonthRange,
				'bg-white': !isMonthRange
			})}
		>
			<span
				className="align-middle leading-5 pl-2 pr-1 material-symbols-outlined"
				onClick={handlePreviousMonthClick}
			>
				chevron_left
			</span>
			<span
				className="align-middle leading-5"
				onClick={handleMonthClick}
			>
				{dateMonth.format('MMMM YY')}
			</span>
			<span
				className="align-middle leading-5 pl-1 pr-2 material-symbols-outlined"
				onClick={handleNextMonthClick}
			>
				chevron_right
			</span>
		</div>
	)
}

export default MonthsFilter
