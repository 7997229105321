import React from 'react'
import classNames from 'classnames'
import {dateRange, Value} from '../../../../../types/pulse'
import moment from 'moment/moment'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {
	dateFromState,
	dateMonthState,
	dateToState,
	selectedDateRangeState
} from '../../../../../state/pulse-filters'
import {
	calendarDateFromState,
	calendarDateToState
} from '../../../../../state/pulse'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/react'
import Calendar from 'react-calendar'
import YearFilter from './YearFilter'
import AllTimeFilter from './AllTimeFilter'
import H3 from '../Headers/H3'

const CalendarDatesFilter = () => {
	const [dateFrom, setDateFrom] = useRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const setDateMonth = useSetRecoilState(dateMonthState)
	const date = useRecoilValue(selectedDateRangeState)
	const [calendarDateFrom, setCalendarDateFrom] = useRecoilState(calendarDateFromState)
	const [calendarDateTo, setCalendarDateTo] = useRecoilState(calendarDateToState)
	const isSelection = date === dateRange.Custom ||
		date === dateRange.AllTime ||
		(date === dateRange.Year && dateFrom && dateFrom.clone().get('year') < moment().get('year') - 1)

	const handleFromSelection = (date: Value) => {
		const from = date as Date

		setCalendarDateFrom(from)

		if (from && calendarDateTo) {
			setDateFrom(moment(from))
			setDateTo(moment(calendarDateTo))
			setDateMonth(moment(calendarDateTo))
		}
	}

	const handleToSelection = (date: Value) => {
		const to = date as Date

		setCalendarDateTo(to)

		if (calendarDateFrom && to) {
			setDateFrom(moment(calendarDateFrom))
			setDateTo(moment(to))
			setDateMonth(moment(to))
		}
	}

	return (
		<div>
			<Popover className={classNames('left-4 mr-2 top-2',
				'lg:left-0 lg:static lg:top-0', {
					'absolute': isSelection
				})}
			>
				<PopoverButton className="
					inline-flex items-center justify-center w-full
					outline-none"
				>
					<div
						className={classNames('mb-2 px-3 py-[2px]',
							'text-nowrap text-sm tracking-[1px] uppercase',
							'border border-pulse-icon-gray rounded-full',
							'cursor-pointer',
							'hover:bg-pulse-button-blue',
							'lg:mb-0', {
								'bg-pulse-button-blue': isSelection,
								'bg-white': !isSelection
							})}
					>
						<span
							className="
								align-middle mr-1 mb-[2px]
								leading-5 text-base text-pulse-icon-gray
								material-symbols-outlined"
						>
							calendar_month
						</span>
						<span className="align-middle leading-5">Custom</span>
					</div>
				</PopoverButton>
				<PopoverPanel
					anchor="bottom start"
					className="mt-[5px] p-4 z-50
						bg-white
						text-sm uppercase
						border border-pulse-popup-border-gray rounded-lg
						lg:w-auto"
				>
					<div className="flex mb-4">
						<YearFilter
							absolute={false}
							index={-2}
						/>
						<YearFilter
							absolute={false}
							index={-3}
						/>
						<AllTimeFilter/>
					</div>
					<H3>Custom Date</H3>
					<div className="flex space-x-2">
						<Popover className="flex-1">
							<PopoverButton className="
								inline-flex items-center justify-center w-full
								outline-none"
							>
								<div className="
									flex mb-2 pl-3 pr-2 py-[2px] w-full
									bg-white
									border border-pulse-icon-gray rounded-full
									text-nowrap text-sm tracking-[1px] uppercase
									cursor-pointer
									hover:bg-pulse-button-blue
									lg:mb-0"
								>
									<span className="flex-1 leading-5 text-left">From</span>
									<span className="
										align-middle
										leading-5
										material-symbols-outlined"
									>
										keyboard_arrow_down
									</span>
								</div>
							</PopoverButton>
							<PopoverPanel
								anchor="bottom start"
								className="mt-[5px] z-50"
							>
								<Calendar
									className="
										bg-white drop-shadow-[1px_1px_1px_rgba(0,0,0,0.10)]
										border border-pulse-popup-border-gray rounded-lg"
									locale="en-US"
									maxDate={calendarDateTo || undefined}
									onChange={handleFromSelection}
									value={calendarDateFrom}
								/>
							</PopoverPanel>
						</Popover>
						<Popover className="flex-1">
							<PopoverButton className="
								inline-flex items-center justify-center w-full
								outline-none"
							>
								<div className="
									flex mb-2 pl-3 pr-2 py-[2px] w-full
									bg-white
									border border-pulse-icon-gray rounded-full
									text-nowrap text-sm tracking-[1px] uppercase
									cursor-pointer
									hover:bg-pulse-button-blue
									lg:mb-0"
								>
									<span className="flex-1 leading-5 text-left">To</span>
									<span className="
										align-middle
										leading-5
										material-symbols-outlined"
									>
										keyboard_arrow_down
									</span>
								</div>
							</PopoverButton>
							<PopoverPanel
								anchor="bottom start"
								className="mt-[5px] z-50"
							>
								<Calendar
									className="
										bg-white drop-shadow-[1px_1px_1px_rgba(0,0,0,0.10)]
										border border-pulse-popup-border-gray rounded-lg"
									locale="en-US"
									minDate={calendarDateFrom || undefined}
									onChange={handleToSelection}
									value={calendarDateTo}
								/>
							</PopoverPanel>
						</Popover>
					</div>
				</PopoverPanel>
			</Popover>
		</div>
	)
}

export default CalendarDatesFilter
