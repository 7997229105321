import React from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import {useRecoilValue} from 'recoil'
import {
	memberTransactionsOutOfRangeState,
	titleSuffixState
} from '../../state/pulse'
import DataAvailabilityWarningOverlay from './components/DataAvailabilityWarningOverlay'
import ValueBandGraph from './components/ValueBandGraph'
import ProtectionPeriodBandGraph from './components/ProtectionPeriodBandGraph'
import RefundPeriodBandGraph from './components/RefundPeriodBandGraph'
import H2 from './components/Common/Headers/H2'

const PulseBandsView = () => {
	const memberTransactionsOutOfRange = useRecoilValue(memberTransactionsOutOfRangeState)
	const titleSuffix = useRecoilValue(titleSuffixState)

	return (
		<DashboardPageLayout>
			<section className="
				max-w-6xl mb-8 px-4 relative w-full
				lg:mx-auto lg:px-10"
			>
				{!memberTransactionsOutOfRange && (
					<>
						<H2>Bands - {titleSuffix}</H2>
						<ValueBandGraph/>
						<ProtectionPeriodBandGraph/>
						<RefundPeriodBandGraph/>
					</>
				)}
				{memberTransactionsOutOfRange && (<DataAvailabilityWarningOverlay/>)}
			</section>
		</DashboardPageLayout>
	)
}

export default PulseBandsView
