import React, {ReactElement} from 'react'
import Logo from '../Images/Logo'

interface LogoSpinnerProps {
	children: ReactElement,
	isLoading: boolean,
	className?: string,
}

const LoadingSpinner = (props: LogoSpinnerProps) => {
	return (<>
		{props.isLoading && (<AnimatedLogo className={props.className}/>)}
		{!props.isLoading && (<>{props.children}</>)}
	</>)
}

const AnimatedLogo = (props: { className?: string }) => {
	return (
		<div className={props.className}>
			<div className="
				flex h-full items-center justify-center mx-auto w-full
				animate-pulse"
			>
				<Logo
					className="h-[38px] opacity-20"
					color="#525252"
				/>
			</div>
		</div>
	)
}

export default LoadingSpinner
