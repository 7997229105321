import React, {ReactNode, useState} from 'react'
import Logo from './Common/Images/Logo'
import {NavLink} from 'react-router-dom'
import {getSsoUrl} from '../../../services/user-service'
import {useRecoilValue} from 'recoil'
import {dashboardRestrictionState} from '../../../state/pulse'
import {dashboardRestriction} from '../../../types/pulse'
import {Dialog} from '@headlessui/react'

const DashboardFooter = () => {
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)
	const [showCookiePolicy, setShowCookiePolicy] = useState(false)
	const [showLegacyLinks, setShowLegacyLinks] = useState(false)

	return (
		<footer className="
			flex flex-1 items-end max-w-6xl mb-20 px-4 w-full
			bg-white
			lg:mx-auto lg:px-10 lg:mb-0"
		>
			<div className="
				py-2 w-full
				border-gray-200 border-t
				lg:py-5"
			>
				<div className="flex items-center justify-between">
					<div
						className="flex flex-shrink-0 items-center mr-2"
						onClick={() => setShowLegacyLinks(true)}
					>
						<Logo
							className="h-[38px] opacity-20"
							color="#525252"
						/>
					</div>
					<div className="flex-1">
						<span
							className="
								text-pulse-label-gray
								cursor-pointer
								hover:text-neutral-400"
							onClick={() => setShowCookiePolicy(true)}
						>
							Cookie Policy
						</span>
						{dashboardRestrictions !== dashboardRestriction.External && showLegacyLinks && (
							<>
								<NavLink
									className="
										ml-2 pl-2
										text-pulse-label-gray
										border-l border-pulse-label-gray
										hover:text-neutral-400"
									to="/"
								>
									Legacy Dashboard
								</NavLink>
								<NavLink
									className="
										ml-2 pl-2
										text-pulse-label-gray
										border-l border-pulse-label-gray
										hover:text-neutral-400"
									to={getSsoUrl() ?? '#'}
								>
									SSO Dashboard
								</NavLink>
							</>
						)}
					</div>
				</div>
			</div>
			<Dialog
				open={showCookiePolicy}
				onClose={() => setShowCookiePolicy(false)}
				className="relative z-50"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
				<div className="fixed flex inset-0 items-center justify-center p-4 w-screen">
					<Dialog.Panel className="
							max-w-sm min-w-[90%] mx-auto px-6 py-12 relative
							bg-white rounded
							md:min-w-[50%]"
					>
						<div
							className="
								absolute right-4 top-4
								cursor-pointer"
							onClick={() => setShowCookiePolicy(false)}
						>
							<span className="material-symbols-outlined">close</span>
						</div>
						<Dialog.Title
							className="
								mb-4 pb-4
								border-b border-pulse-icon-gray"
							as="div"
						>
							<h3 className="font-medium text-xl">
								Protect Group, Pulse Platform and Pulse Reporting Dashboard Cookie Policy
							</h3>
						</Dialog.Title>
						<Dialog.Description as="div">
							<div className="
								h-full max-h-[30rem] overflow-y-scroll pr-4"
							>
								<Paragraph>
									Welcome to the Protect Group, our technology and our products!
									To keep things running smoothly and ensure you have the best possible experience,
									we use cookies (the digital kind, not the chocolate chip kind—sadly).
									Here's everything you need to know about the cookies we use and how they work.
								</Paragraph>

								<Header text="What Are Cookies?"/>
								<Paragraph>
									Cookies are small text files that sit quietly in your browser,
									helping us understand how you interact with the Protect Group and our platforms.
									Think of them as tiny assistants that help us optimize your experience while respecting your privacy.
								</Paragraph>

								<Header text="Why Do We Use Cookies?"/>
								<Paragraph>
									We use cookies to:
								</Paragraph>
								<UnsortedList>
									<ListItem label="Understand usage patterns:">
										Tools like Google Analytics and Microsoft Clarity help us see how you interact with the platform.
										This data helps us make improvements so you can enjoy an even smoother experience.
									</ListItem>
									<ListItem label="Enhance functionality:">
										Some cookies are essential for things like loading pages quickly and ensuring
										the platform behaves as expected.
									</ListItem>
									<ListItem label="Analyse performance:">
										We track what’s working and what’s not, so we can deliver a better experience for
										you and all our users.
									</ListItem>
								</UnsortedList>

								<Header text="The Cookies We Use"/>
								<Paragraph>
									Here’s a breakdown of the cookies we use:
									<SortedList>
										<ListItem>
											Necessary Cookies
											<UnsortedList>
												<ListItem>
													These are the cookies that keep the platform running.
													They manage essential features like logging in or remembering your preferences.
												</ListItem>
											</UnsortedList>
										</ListItem>
										<ListItem>
											Google Analytics Cookies
											<UnsortedList>
												<ListItem>
													These cookies give us insights into how you navigate the platform,
													like which pages you visit and how long you stay.
												</ListItem>
												<ListItem>
													Don’t worry—we anonymise your data wherever possible,
													so it’s all about trends, not tracking you individually.
												</ListItem>
											</UnsortedList>
										</ListItem>
										<ListItem>
											Microsoft Clarity Cookies
											<UnsortedList>
												<ListItem>
													These cookies help us understand how you interact with the platform by capturing
													details like clicks and scrolls.
												</ListItem>
												<ListItem>
													This helps us make the platform more intuitive and user-friendly.
												</ListItem>
											</UnsortedList>
										</ListItem>
										<ListItem>
											Google Tag Manager Cookies
											<UnsortedList>
												<ListItem>
													Google Tag Manager (GTM) helps us efficiently manage all the tracking tools
													we use on the Pulse Platform.
												</ListItem>
												<ListItem>
													GTM cookies enable us to deploy tags for analytics and other purposes without
													slowing down the platform or affecting your experience.
												</ListItem>
												<ListItem>
													These cookies don’t collect data directly but ensure other tools (like Google Analytics)
													work seamlessly.
												</ListItem>
											</UnsortedList>
										</ListItem>
									</SortedList>
								</Paragraph>

								<Header text="Your Cookie Choices"/>
								<Paragraph>
									Your privacy matters to us, and we believe in putting you in control. Here’s what you can do:
								</Paragraph>
								<UnsortedList>
									<ListItem label="Manage settings:">
										You can tweak your browser settings to accept, reject, or delete cookies at any time.
									</ListItem>
									<ListItem label="Opt out:">
										Want to avoid Google Analytics? Use their opt-out tool.
									</ListItem>
									<ListItem label="Still unsure?">
										<span>Get in touch with us at </span>
										<a className="text-pulse-blue" href="mailto:hello@protect.group">hello@protect.group</a>
										<span>—we’re here to help!</span>
									</ListItem>
								</UnsortedList>

								<Header text="What Happens If You Disable Cookies?"/>
								<Paragraph>
									Disabling cookies might affect some features of the platform. While the core experience will
									still work, certain functions might not be as seamless.
								</Paragraph>

								<Header text="Updates to This Policy"/>
								<Paragraph>
									We may update this Cookie Policy from time to time to reflect
									changes in technology, laws, or our services.
									When we do, we’ll make sure you know—no surprises here!
								</Paragraph>

								<Header text="Questions? Suggestions? Craving actual cookies? 🍪"/>
								<Paragraph>
									<span>Contact us at </span>
									<a className="text-pulse-blue" href="mailto:hello@protect.group">hello@protect.group</a>
									<span> or visit </span>
									<a className="text-pulse-blue" href="https://www.protect.group/platforms/security-and-performance" target="_blank">
										https://www.protect.group/platforms/security-and-performance
									</a>
									<span> to learn more about our commitment to transparency and user privacy.</span>
								</Paragraph>
								<Paragraph>
									Thanks for being part of Protect Group
								</Paragraph>
							</div>
						</Dialog.Description>
					</Dialog.Panel>
				</div>
			</Dialog>
		</footer>
	)
}

const Header = (props: {text: string}) => (<h3 className="font-medium mb-4 text-lg">{props.text}</h3>)
const Paragraph = (props: {children: ReactNode}) => (<p className="mb-4">{props.children}</p>)
const UnsortedList = (props: {children: ReactNode}) => (<ul className="list-disc my-4 mx-6">{props.children}</ul>)
const SortedList = (props: {children: ReactNode}) => (<ol className="list-decimal my-4 mx-6">{props.children}</ol>)
const ListItem = (props: {
	children: ReactNode
	label?: string
}) => (
	<li className="mb-2">
		{props.label && (<span className="font-medium">{props.label} </span>)}
		{props.children}
	</li>
)

export default DashboardFooter
