import React from 'react'

const Logo = (props: { className?: string, color?: string }) => {
	const fillColor = props.color || '#164FF8'

	return (
		<svg
			className={props.className}
			fill="none"
			height="62"
			viewBox="0 0 71 62"
			width="71"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24.1745 61.428H16.9515C7.60435 61.428 0 53.7678 0 44.352C0 34.9363 7.60435 27.2761 16.9515 27.2761H53.0636C58.3186 27.2761 62.5935 22.9698 62.5935 17.6762C62.5935 12.3827 58.3186 8.07634 53.0636 8.07634H38.7184V23.7809H31.2968V15.2509C31.2968 7.17185 37.8205 0.600281 45.8407 0.600281H53.0636C62.4108 0.600281 70.0151 8.26044 70.0151 17.6762C70.0151 27.092 62.4108 34.7522 53.0636 34.7522H16.9515C11.6966 34.7522 7.42159 39.0585 7.42159 44.352C7.42159 49.6456 11.6966 53.9519 16.9515 53.9519H31.2968V49.1013H38.5356L38.5171 49.2187C37.3384 56.2919 31.3074 61.4253 24.1745 61.4253V61.428Z"
				fill={fillColor}/>
			<path
				d="M35.0077 45.6647C32.9629 45.6647 31.2969 43.9891 31.2969 41.9267C31.2969 39.8642 32.9602 38.1887 35.0077 38.1887C37.0551 38.1887 38.7185 39.8642 38.7185 41.9267C38.7185 43.9891 37.0551 45.6647 35.0077 45.6647Z"
				fill={fillColor}/>
		</svg>
	)
}

export default Logo
