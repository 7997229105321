import React, {useEffect} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import DashboardSupport from './components/DashboardSupport'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
	exportDataState,
	exportFormContentState,
	memberTransactionsOutOfRangeState,
	titleSuffixState
} from '../../state/pulse'
import useExportTransactionData from './hooks/useExportTransactionData'
import TransactionTable from './components/TransactionTable'
import TransactionBatchModal from './components/Common/Transactions/TransactionBatchModal'
import TransactionOverviewModal from './components/Common/Transactions/TransactionOverviewModal'
import H2 from './components/Common/Headers/H2'
import DataAvailabilityWarningOverlay from './components/DataAvailabilityWarningOverlay'
import TransactionExportFormContent from './components/Common/Transactions/TransactionExportFormContent'

const PulseTransactionsView = () => {
	const exportData = useExportTransactionData()
	const titleSuffix = useRecoilValue(titleSuffixState)
	const memberTransactionsOutOfRange = useRecoilValue(memberTransactionsOutOfRangeState)
	const setExportDataState = useSetRecoilState(exportDataState)
	const setExportFormContent = useSetRecoilState(exportFormContentState)

	useEffect(() => {
		setExportFormContent(<TransactionExportFormContent/>)
		setExportDataState(exportData)
	}, [exportData])

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl px-4 relative w-full
					lg:mx-auto lg:px-10"
				>
					<H2>Transactions - {titleSuffix}</H2>
					{!memberTransactionsOutOfRange && (
						<div className="border-b border-bottom border-gray-200">
							<TransactionTable/>
							<TransactionOverviewModal/>
							<TransactionBatchModal/>
						</div>
					)}
					{memberTransactionsOutOfRange && (<DataAvailabilityWarningOverlay/>)}
				</section>
				<section>
					<div className="
						max-w-6xl px-4 py-8 w-full
						lg:mx-auto lg:px-10"
					>
						<DashboardSupport/>
					</div>
				</section>
			</>
		</DashboardPageLayout>
	)
}

export default PulseTransactionsView
