import classNames from 'classnames'
import React, {ReactElement} from 'react'
import { dashboardRestriction } from '../../../../types/pulse'
import {useRecoilValue} from 'recoil'
import {dashboardRestrictionState} from '../../../../state/pulse'

export const TableHeader = (props: {
	className?: string
	dashboardRestriction?: dashboardRestriction
	title: string
}) => {
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	if (props.dashboardRestriction !== undefined && props.dashboardRestriction !== dashboardRestrictions) {
		return null
	}
	
	return (
		<th className={classNames('px-2 py-2',
			'font-medium text-left',
			props.className)}
		>
			{props.title}
		</th>
	)
}

export const CardHeader = (props: {
	className?: string
	dashboardRestriction?: dashboardRestriction
	title: string
}) => {
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	if (props.dashboardRestriction !== undefined && props.dashboardRestriction !== dashboardRestrictions) {
		return null
	}

	return (
		<div className={classNames('font-medium text-left',
			props.className)}
		>
			{props.title}
		</div>
	)
}

export const TableColumn = (props: {
	className?: string
	dashboardRestriction?: dashboardRestriction
	value: ReactElement
}) => {
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	if (props.dashboardRestriction !== undefined && props.dashboardRestriction !== dashboardRestrictions) {
		return null
	}

	return (
		<td className={classNames('px-2 py-2',
			'text-left',
			props.className)}
		>
			{props.value}
		</td>
	)
}

export const CardColumn = (props: {
	className?: string
	dashboardRestriction?: dashboardRestriction
	style?: React.CSSProperties | undefined
	value: ReactElement
}) => {
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	if (props.dashboardRestriction !== undefined && props.dashboardRestriction !== dashboardRestrictions) {
		return null
	}

	return (
		<div
			className={classNames('text-left',
				props.className)}
			style={props.style}
		>
			{props.value}
		</div>
	)
}