import {atom, selector} from 'recoil'
import {Moment} from 'moment/moment'
import {dateFormat} from '../utils/date'
import moment from 'moment'
import {dateRange, graphFilterType} from '../types/pulse'
import {extendedCoreCurrencyDropdownData} from '../utils/data'

const keyPrefix = 'pulse'

export const dateFromState = atom<Moment | null>({
	key: `${keyPrefix}-dateFrom`,
	default: moment().startOf('year')
})

export const dateToState = atom<Moment | null>({
	key: `${keyPrefix}-dateTo`,
	default: moment().startOf('day')
})

export const dateMonthState = atom<Moment>({
	key: `${keyPrefix}-dateMonth`,
	default: moment()
})

export const formattedDateFromState = selector<string>({
	key: `${keyPrefix}-formattedDateFrom`,
	get: ({get}) => get(dateFromState)?.format(dateFormat) || ''
})

export const formattedDateToState = selector<string>({
	key: `${keyPrefix}-formattedDateTo`,
	get: ({get}) => get(dateToState)?.format(dateFormat) || ''
})

export const selectedDateRangeState = selector<dateRange>({
	key: `${keyPrefix}-selectedDateRange`,
	get: ({get}) => {
		const fromDate = get(dateFromState)
		const toDate = get(dateToState)

		if (!toDate) {
			return dateRange.AllTime
		}

		const formattedFromDate = get(formattedDateFromState)
		const formattedToDate = get(formattedDateToState)
		const beginningOfYear = moment().startOf('year').format(dateFormat)
		const beginningOfMonth = moment().startOf('month').format(dateFormat)
		const today = moment().format(dateFormat)
		const beginningOfSelectedYear = fromDate
			? fromDate.clone().startOf('year').format(dateFormat)
			: ''
		const endOfSelectedYear = fromDate
			? fromDate.clone().endOf('year').format(dateFormat)
			: ''
		const beginningOfSelectedMonth = fromDate
			? fromDate.clone().startOf('month').format(dateFormat)
			: ''
		const endOfSelectedMonth = fromDate
			? fromDate.clone().endOf('month').format(dateFormat)
			: ''

		if (formattedFromDate === beginningOfMonth && formattedToDate === today) {
			return dateRange.MonthToDate
		}

		if (formattedFromDate === beginningOfYear && formattedToDate === today) {
			return dateRange.YearToDate
		}

		if (formattedFromDate === beginningOfSelectedYear && formattedToDate === endOfSelectedYear) {
			return dateRange.Year
		}

		if (formattedFromDate === beginningOfSelectedMonth && formattedToDate === endOfSelectedMonth) {
			return dateRange.Month
		}

		return dateRange.Custom
	}
})

export const selectedMemberIdState = atom<number | null>({
	key: `${keyPrefix}-selectedMemberId`,
	default: null
})

export const selectedRegionIdState = atom<number | null>({
	key: `${keyPrefix}-selectedRegionId`,
	default: null
})

export const selectedCoreCurrencyState = atom<string>({
	key: `${keyPrefix}-selectedCoreCurrency`,
	default: 'USD'
})

export const selectedCoreCurrencyNameState = selector<string>({
	key: `${keyPrefix}-selectedCoreCurrencyNameState`,
	get: ({get}) => {
		const selectedCurrency = get(selectedCoreCurrencyState)
		return extendedCoreCurrencyDropdownData.find(item => item.value === selectedCurrency)?.name || ''
	}
})

export const selectedGraphFiltersState = atom<graphFilterType[]>({
	key: `${keyPrefix}-selectedGraphFilters`,
	default: [
		graphFilterType.RefundProtectValue,
		graphFilterType.YourRevenue
	]
})

export const selectedProductIdState = atom<number | null>({
	key: `${keyPrefix}-selectedProductId`,
	default: null
})

export const selectedVerticalIdState = atom<number | null>({
	key: `${keyPrefix}-selectedVerticalId`,
	default: null
})