import React, {useEffect, useState} from 'react'
import DefaultButton from './Common/Buttons/DefaultButton'
import {CardColumn, CardHeader} from './Common/TableElements'
import {Dialog} from '@headlessui/react'
import ActionButton from './Common/Buttons/ActionButton'
import {pulseCookieConsent} from '../../../types/pulse'
import {useRecoilState} from 'recoil'
import {cookieConsentPopupState, cookieConsentState} from '../../../state/pulse'
import Tooltip from './Common/Tooltip/Tooltip'

const DashboardCookieConsent = () => {
	const [cookieConsent, setCookieConsent] = useRecoilState(cookieConsentState)
	const [cookieConsentPopup, setCookieConsentPopup] = useRecoilState(cookieConsentPopupState)
	const [showDetails, setShowDetails] = useState(false)

	useEffect(() => {
		if (cookieConsent) {
			return
		}

		const consent = localStorage.getItem(pulseCookieConsent)

		setCookieConsent(consent || '0')
		setCookieConsentPopup(!consent)
		setConsentState(consent === '1', false)
	}, [])

	const setConsentState = (consent: boolean, setStorage: boolean) => {
		const clarity = window[('clarity' as any)] as any

		if (clarity) {
			clarity('consent', consent)
		}

		(window[`ga-disable-${process.env.REACT_APP_G_ANALYTICS}` as any] as any) = !consent
		setCookieConsent(consent ? '1' : '0')

		if (setStorage) {
			localStorage.setItem(pulseCookieConsent, consent ? '1' : '0')
		}
	}

	const handleAccept = () => {
		setConsentState(true, true)
		setCookieConsentPopup(false)
	}

	const handleDecline = () => {
		setConsentState(false, true)
		setCookieConsentPopup(false)
	}

	const handleDetailsClose = () => {
		setShowDetails(false)
	}

	return (
		<>
			{cookieConsentPopup && (
				<div className="
					bottom-24 fixed left-0 p-6 max-w-lg mx-auto right-0 z-20
					bg-white
					text-sm/5
					border border-pulse-icon-gray rounded-lg
					lg:bottom-6"
				>
					<div className="
						mb-6
						font-medium text-base"
					>
						We value your privacy
					</div>
					<div className="mb-6">
						We use cookies to enhance your browsing experience and analyze our traffic.
						By clicking “Accept”, you consent to our use of cookies.
					</div>
					<div className="flex space-x-2">
						<ActionButton text="Accept" onClick={handleAccept}/>
						<DefaultButton text="Reject" onClick={handleDecline}/>
						<DefaultButton text="Details" onClick={() => setShowDetails(true)}/>
					</div>
				</div>
			)}
			{showDetails && (
				<Dialog
					open={showDetails}
					onClose={handleDetailsClose}
					className="relative z-50"
				>
					<div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
					<div className="
						fixed flex inset-0 items-center justify-center p-4 w-screen"
					>
						<Dialog.Panel className="
							max-w-sm min-w-[90%] mx-auto px-6 py-12 relative
							bg-white rounded
							md:min-w-[50%]"
						>
							<div
								className="
									absolute right-4 top-4
									cursor-pointer"
								onClick={handleDetailsClose}
							>
								<span className="material-symbols-outlined">close</span>
							</div>
							<Dialog.Title
								className="
									mb-4 pb-4
									border-b border-pulse-icon-gray"
								as="div"
							>
								<h3 className="font-medium">Details about the cookies</h3>
							</Dialog.Title>
							<Dialog.Description as="div">
								<div className="
									h-full max-h-96 overflow-y-scroll pr-4"
								>
									<p className="
										mb-4
										text-pulse-label-gray"
									>
										We use cookies to help you navigate efficiently and perform certain functions.
										You will find detailed information about all cookies below.
									</p>
									<p className="
										mb-4
										text-pulse-label-gray">
										The cookies that are categorized as "Necessary" are stored on your browser as they are essential
										for enabling the basic functionalities of the site.
									</p>
									<p className="
										mb-4
										text-pulse-label-gray">
										We also use third-party cookies that help us analyze how you use this website,
										store your preferences, and provide the content and advertisements that are relevant to you.
										These cookies will only be stored in your browser with your prior consent.
									</p>
									<div className="mb-6">
										<div className="flex mb-4 space-x-2">
											<h4 className="flex-1 font-medium">Necessary</h4>
											<div className="font-medium text-pulse-green">Always Active</div>
										</div>
										<div className="
											bg-pulse-card-gray
											divide-pulse-popup-border-gray divide-y
											rounded-lg"
										>
											<CookieCard
												description="Used to prevent cross site forgery."
												duration="session"
												name=".AspNetCore.Antiforgery.*"
											/>
											<CookieCard
												description="Used to store information about the currently authenticated user"
												duration="session"
												name=".AspNetCore.Identity.Application"
											/>
											<CookieCard
												description="Azure: Allows an end user to talk to the same Azure App Service worker
														instance until session finishes."
												duration="session"
												name="ARRAffinity"
											/>
											<CookieCard
												description="Azure: ensures that the request is always sent back to the same backend instance."
												duration="session"
												name="ARRAffinitySameSite"
											/>
											<CookieCard
												description="Used for the check session endpoint for browser-based JavaScript clients at
														signout time. It is kept in sync with the authentication cookie,
														and is removed when the user signs out."
												duration="session"
												name="idsrv.session"
											/>
											<CookieCard
												description="Azure: Set by Azure to determine which web server the users must be directed to."
												duration="1 hour"
												name="TiPMix"
											/>
											<CookieCard
												description="Azure: Used for routing production traffic by specifying the production slot."
												duration="1 hour"
												name="x-ms-routing-name"
											/>
											<StorageCard
												description="Used to store information about the currently authenticated user"
												name="oidc.*"
												type="Local/Session"
											/>
											<StorageCard
												description="Used to store information about the currently authenticated user"
												name="pgd:*"
												type="Local/Session"
											/>
										</div>
									</div>
									<div>
										<h4 className="
											mb-4
											font-medium"
										>
											Analytics
										</h4>
										<div className="
											bg-pulse-card-gray
											divide-pulse-popup-border-gray divide-y
											rounded-lg"
										>
											<CookieCard
												description="Microsoft Clarity: Persists the Clarity User ID and preferences,
														unique to that site is attributed to the same user ID."
												duration="1 year"
												name="_clck"
											/>
											<CookieCard
												description="Microsoft Clarity: Connects multiple page views by a user into a single
													Clarity session recording."
												duration="1 day"
												name="_clsk"
											/>
											<CookieCard
												description="Google Analytics: Used to distinguish users."
												duration="2 years"
												name="_ga"
											/>
											<CookieCard
												description="Google Analytics: Used to persist session state."
												duration="2 years"
												name="_ga_*"
											/>
											<CookieCard
												description="Microsoft Clarity: Indicates whether MUID is transferred to ANID,
													a cookie used for advertising. Clarity doesn't use ANID and so this is always set to 0."
												duration="10 minutes"
												name="ANONCHK"
											/>
											<CookieCard
												description="Microsoft Clarity: Identifies the first-time Clarity saw this user on any
													site using Clarity."
												duration="1 year"
												name="CLID"
											/>
											<CookieCard
												description="Microsoft Clarity: Indicates whether to refresh MUID."
												duration="7 days"
												name="MR"
											/>
											<CookieCard
												description="Microsoft Clarity: Identifies unique web browsers visiting Microsoft sites.
														These cookies are used for advertising, site analytics, and other operational purposes."
												duration="1 year 24 days"
												name="MUID"
											/>
											<CookieCard
												description="Microsoft Clarity: Used in synchronizing the MUID across Microsoft domains."
												duration="session"
												name="SM"
											/>
											<StorageCard
												description="Microsoft Clarity: Tracks users' specific interactions"
												name="_cltk"
												type="Session"
											/>
										</div>
									</div>
								</div>
							</Dialog.Description>
						</Dialog.Panel>
					</div>
				</Dialog>
			)}
			<Tooltip
				id="cookieconsent-popup"
				tooltipClassName="
					bottom-24 fixed left-6 z-10
					lg:bottom-6"
				tooltipText="Click to configure your cookie settings"
				tooltipTitle="Cookies"
			>
				<div
					className="
						p-2
						bg-pulse-card-gray
						text-pulse-label-gray
						rounded-full
						cursor-pointer
						material-symbols-outlined
						hover:opacity-60"
					onClick={() => setCookieConsentPopup(true)}
				>
					cookie
				</div>
			</Tooltip>
		</>
	)
}

const CookieCard = (props: {
	description: string
	duration: string
	name: string
}) => {
	return (
		<div className="gap-2 grid grid-cols-[125px_auto] p-4 w-full">
			<CardHeader title="Cookie"/>
			<CardColumn value={<>{props.name}</>}/>
			<CardHeader title="Description"/>
			<CardColumn value={<>{props.description}</>}/>
			<CardHeader title="Duration"/>
			<CardColumn value={<>{props.duration}</>}/>
		</div>
	)
}

const StorageCard = (props: {
	description: string
	name: string
	type: string
}) => {
	return (
		<div className="gap-2 grid grid-cols-[125px_auto] p-4 w-full">
			<CardHeader title={`${props.type} storage`}/>
			<CardColumn value={<>{props.name}</>}/>
			<CardHeader title="Description"/>
			<CardColumn value={<>{props.description}</>}/>
		</div>
	)
}

export default DashboardCookieConsent
