import {Bar} from 'react-chartjs-2'
import React from 'react'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import H3 from './Common/Headers/H3'
import useGetRefundPeriodBandBarChartData from '../hooks/useGetRefundPeriodBandBarChartData'
import ExportFilter from './Common/Filter/ExportFilter'
import useExportRefundPeriodBandData from '../hooks/useExportRefundPeriodBandData'
import Tooltip from './Common/Tooltip/Tooltip'

const RefundPeriodBandGraph = () => {
	const chartData = useGetRefundPeriodBandBarChartData()
	const exportChartData = useExportRefundPeriodBandData()

	return (
		<div className="
			mb-0 space-x-3
			md:mb-4 md:flex"
		>
			<div className="flex-1">
				<div className="flex">
					<div className="flex-1">
						<H3 className="relative inline-block">
							<span>Refund Application Time Bands</span>
							<div className="font-normal normal-case">
								<Tooltip
									id="refundPeriodBands-popup"
									tooltipTitle="Refund Application Time Bands"
									tooltipText="Ranges of time between the refund request date and booking date,
										each with their respective volumes."
									tooltipClassName="absolute -right-6 top-0"
								/>
							</div>
						</H3>
					</div>
					<ExportFilter exportData={exportChartData}/>
				</div>
				<LoadingSpinner
					isLoading={chartData.isLoading}
					className="flex items-center justify-center min-h-96 w-full"
				>
					<Bar
						className="max-w-full mb-4"
						data={{
							datasets: chartData.getDatasets(),
							labels: chartData.getLabels()
						}}
						options={chartData.getOptions()}
					/>
				</LoadingSpinner>
			</div>
		</div>
	)
}

export default RefundPeriodBandGraph
