import React from 'react'
import PrimaryValueCard from './Common/Cards/PrimaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewProtectGroupProfitValueSummary = () => {
	const {getProtectGroupProfitValueSummary, getProtectGroupProfitTrend} = usePulseApi()
	const value = useApiValue(filterState, getProtectGroupProfitValueSummary, 0)
	const trendRate = useApiValue(filterState, getProtectGroupProfitTrend, 0)

	return (
		<PrimaryValueCard
			value={value.data}
			trendValue={trendRate.data}
			header="PG Gross Margin"
			tooltipText="Net revenue from Refund Protect sales after deducting the total value of approved refunds."
			valueClassName="
				text-pulse-leadership-card-primary-title
				[text-shadow:1px_1px_1px_#FFF]"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewProtectGroupProfitValueSummary
