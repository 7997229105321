import {Checkbox, Field} from '@headlessui/react'
import {Label} from 'reactstrap'
import React, {useEffect, useState} from 'react'
import {graphFilterType} from '../../../../../types/pulse'
import {useRecoilState} from 'recoil'
import {selectedGraphFiltersState} from '../../../../../state/pulse-filters'
import useGetGraphFilterLabel from '../../../hooks/useGetGraphFilterLabel'

interface GraphFilterFieldProps {
	key: string,
	type: graphFilterType,
	types: graphFilterType[]
}

const GraphFilterField = (props: GraphFilterFieldProps) => {
	const [enabled, setEnabled] = useState(false)
	const [graphFilters, setGraphFilters] = useRecoilState(selectedGraphFiltersState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	useEffect(() => {
		setEnabled(graphFilters.includes(props.type))
	}, [graphFilters])

	const handleOnChange = () => {
		setGraphFilters(current => {
			let next: graphFilterType[] = []

			if (current.includes(props.type)) {
				if (current.length <= 1) {
					return current
				}

				next = [...current]
				next.splice(current.indexOf(props.type), 1)

				return next
			}

			current.forEach(type => {
				if (!props.types.includes(type)) {
					return current
				}

				next.push(type)
			})

			next.push(props.type)

			return next.sort()
		})
	}

	return (
		<Field className="flex items-center gap-2">
			<Checkbox
				checked={enabled}
				onChange={handleOnChange}
				className="
					block size-4
					bg-neutral-200
					rounded border-2 border-black
					cursor-pointer group
					data-[checked]:bg-neutral-200"
			>
				<svg
					className="
						opacity-0 stroke-black
						group-data-[checked]:opacity-100"
					viewBox="0 0 14 14"
					fill="none">
					<path
						d="M3 8L6 11L11 3.5"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</Checkbox>
			<Label
				className="cursor-pointer"
				onClick={handleOnChange}
			>
				{getGraphFilterLabel(props.type)}
			</Label>
		</Field>
	)
}

export default GraphFilterField
