import React from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {Menu} from '@headlessui/react'
import {exportTransactionYearState, supportDetailState} from '../../../../../state/pulse'
import moment from 'moment/moment'

const TransactionExportFormContent = () => {
	const [year, setYear] = useRecoilState(exportTransactionYearState)
	const supportDetail = useRecoilValue(supportDetailState)
	const activeFromDate = supportDetail.activeFromDate
		? moment(supportDetail.activeFromDate)
		: moment()
	const maxYear = moment().year()
	const minYear = activeFromDate.year()

	return (
		<>
			<div className="
				mb-1
				leading-5 text-sm tracking-[1px] uppercase"
			>
				Transaction Export
			</div>
			<div className="
				mb-2
				leading-5 text-pulse-label-gray text-sm tracking-[1px]"
			>
				Choose the year
			</div>
			<div className="mb-4">
				<Menu>
					<Menu.Button className="
						mb-2 pl-3 pr-2 py-[2px]
						bg-white
						border border-pulse-icon-gray rounded-full
						text-sm text-nowrap tracking-[1px] uppercase
						cursor-pointer
						hover:bg-pulse-button-blue
						lg:mb-0"
					>
						<span className="
							align-middle leading-5"
						>
							{year}
						</span>
						<span className="
							align-middle pl-1
							leading-5
							material-symbols-outlined"
						>
							keyboard_arrow_down
						</span>
					</Menu.Button>
					<Menu.Items className="
						max-h-48 mt-2 overflow-y-scroll
						border border-pulse-popup-border-gray rounded-lg"
					>
						{Array
							.from({length: maxYear - minYear + 1}, (_, i) => maxYear - i)
							.map((x) => (
								<Menu.Item
									as="div"
									className="
										px-6 py-1
										cursor-pointer
										data-[focus]:bg-pulse-button-blue"
									key={x}
									onClick={()=>setYear(x)}
								>
									{x}
								</Menu.Item>
							))
						}
					</Menu.Items>
				</Menu>
			</div>
		</>
	)
}

export default TransactionExportFormContent
