import React, {useRef} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import classNames from 'classnames'
import {dashboardPaths, Dashboards, Roles} from '../../../types'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/react'
import DashboardFilter from './DashboardFilter'
import {signOut} from '../../../services/user-service'
import {useRecoilState, useRecoilValue} from 'recoil'
import {dashboardLoadingState, dashboardRestrictionState, userDetailState} from '../../../state/pulse'
import Logo from './Common/Images/Logo'
import {userHasRoleState} from '../../../state/auth'
import {dashboardRestriction} from '../../../types/pulse'

const DashboardHeader = () => {
	const dashboardLoading = useRecoilValue(dashboardLoadingState)

	return (
		<>
			<div className="relative mb-28 z-100 lg:mb-40">
				<header
					className={classNames([
						'fixed top-0 w-full z-20',
						'bg-white text-xs',
						'transition ease-in-out',
						'data-[closed]:opacity-0',
						'data-[enter]:duration-500 data-[enter]:data-[closed]:-translate-y-full',
						'data-[leave]:duration-300 data-[leave]:data-[closed]:-translate-y-full',
					])}
				>
					<div className="max-w-6xl mx-auto relative">
						<div className="
							flex items-center justify-between px-4
							lg:px-10 lg:py-0"
						>
							<div className="flex flex-shrink-0 items-center mr-2">
								<Logo className="h-[38px]"/>
							</div>
							<HeaderContent/>
						</div>
						{!dashboardLoading && (
							<>
								<DashboardFilter/>
								<DashboardRestrictionsToggle/>
							</>
						)}
						<div className="
							border-gray-200
							lg:border-b lg:mx-10"
						/>
					</div>
				</header>
			</div>
		</>
	)
}

const HeaderContent = () => {
	const userDetail = useRecoilValue(userDetailState)
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)
	const otherButtonRef = useRef<HTMLButtonElement>(null)

	const handleLogOut = async () => await signOut()

	const handlePopoverHover = () => {
		if (!otherButtonRef.current || otherButtonRef.current.hasAttribute('data-open')) {
			return
		}

		otherButtonRef.current.click()
	}

	const handlePopoverLeave = () => {
		if (!otherButtonRef.current || !otherButtonRef.current.hasAttribute('data-open')) {
			return
		}

		otherButtonRef.current.click()
	}

	return (
		<div className="flex flex-grow items-center w-auto">
			<nav className="
				flex-grow hidden
				tracking-[1px] uppercase
				lg:block"
			>
				<HeaderLink text="Overview" dashboard={Dashboards.PulseOverview}/>
				<HeaderLink text="Revenue Trends" dashboard={Dashboards.PulseRevenueTrends}/>
				<HeaderLink text="Customer Trends" dashboard={Dashboards.PulseCustomerTrends}/>
				<HeaderLink text="Transactions" dashboard={Dashboards.PulseTransactions}/>
				{(dashboardRestrictions === dashboardRestriction.Leadership || dashboardRestrictions === dashboardRestriction.Internal) && (
					<Popover className="inline-block">
						<PopoverButton ref={otherButtonRef} onMouseEnter={handlePopoverHover} className="block mt-4 mr-6
							text-black text-xs uppercase
							outline-none
							lg:inline-block lg:mt-0
							hover:text-pulse-blue"
						>
							<span>Other</span>
						</PopoverButton>
						<PopoverPanel onMouseLeave={handlePopoverLeave} anchor="bottom start" className="text-xs z-20">
							<div className="
								m-[2px] mt-[5px] relative
								bg-white
								border border-pulse-popup-border-gray rounded-lg"
							>
								<div className="
									flex flex-col px-5 py-4 space-y-3
									text-xs tracking-[1px] uppercase"
								>
									<HeaderLink
										dashboard={Dashboards.PulsePartners}
										text="Partners"
									/>
									<HeaderLink
										dashboard={Dashboards.PulseVerticals}
										text="Verticals"
									/>
									<HeaderLink
										dashboard={Dashboards.PulseBands}
										text="Bands"
									/>
									<HeaderLink
										dashboard={Dashboards.PulsePageAnalytics}
										dashboardRestriction={dashboardRestriction.Leadership}
										text="User History"
									/>
								</div>
							</div>
						</PopoverPanel>
					</Popover>
				)}
			</nav>
			<div className="ml-auto">
				<Popover>
					<PopoverButton className="
						inline-flex items-center justify-center min-h-16 w-full
						outline-none
						hover:text-pulse-blue"
					>
						<span className="
							block
							tracking-[1px] uppercase"
						>
							{userDetail.memberName}
						</span>
						<div className="pt-[4px]">
							<span className="material-symbols-outlined">
								arrow_drop_down
							</span>
						</div>
						<div className="
							hidden my-2 p-1
							border border-pulse-icon-gray rounded-full
							lg:block"
						>
							<span className="
								h-8 w-8
								text-2xl text-pulse-icon-gray
								material-symbols-outlined"
							>
								person
							</span>
						</div>
					</PopoverButton>
					<PopoverPanel anchor="bottom end" className="text-xs z-20">
						<div className="
							m-[2px] mt-0 relative
							bg-white
							border border-pulse-popup-border-gray rounded-lg"
						>
							<div className="flex flex-col px-5 py-4">
								<div className="mb-2">{userDetail.userName}</div>
								<NavLink
									to={dashboardPaths[Dashboards.PulseSettings]}
									className="
										inline-flex
										items-center
										cursor-pointer
										hover:text-pulse-blue"
								>
									<span className="
										mr-1
										text-xl
										material-symbols-outlined">tune</span>
									<span>Settings</span></NavLink>
								<div
									className="
										items-center inline-flex
										cursor-pointer
										hover:text-pulse-blue"
									onClick={handleLogOut}
								>
									<span className="
										mr-1
										text-xl
										material-symbols-outlined">logout</span>
									<span>Logout</span>
								</div>
							</div>
						</div>
					</PopoverPanel>
				</Popover>
			</div>
		</div>
	)
}

const HeaderLink = (props: {
	text: string
	dashboard: Dashboards,
	dashboardRestriction?: dashboardRestriction
}) => {
	const {pathname} = useLocation()
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	if (props.dashboardRestriction !== undefined && props.dashboardRestriction !== dashboardRestrictions) {
		return null
	}

	return (
		<NavLink
			to={dashboardPaths[props.dashboard]}
			className={classNames('block mt-4 mr-6',
				'text-black',
				'lg:inline-block lg:mt-0',
				'hover:text-pulse-blue', {
					'text-pulse-blue': pathname === dashboardPaths[props.dashboard]
				})}
		>
			{props.text}
		</NavLink>
	)
}

const DashboardRestrictionsToggle = () => {
	const userPulseLeadershipRole = useRecoilValue(userHasRoleState(Roles.PulseDashboardLeadershipUser))
	const [dashboardRestrictions, setDashboardRestrictions] = useRecoilState(dashboardRestrictionState)
	const userDetail = useRecoilValue(userDetailState)

	const handleRestriction = () => {
		if (dashboardRestrictions === dashboardRestriction.External) {
			if (userPulseLeadershipRole) {
				setDashboardRestrictions(dashboardRestriction.Leadership)
				return
			}

			setDashboardRestrictions(dashboardRestriction.Internal)
			return
		}

		setDashboardRestrictions(dashboardRestriction.External)
	}

	const dashboardRestrictionName = {
		[dashboardRestriction.External]: 'Partner View',
		[dashboardRestriction.Internal]: 'Internal View',
		[dashboardRestriction.Leadership]: 'Leadership View',
		[dashboardRestriction.Unknown]: ''
	}

	if (!userDetail.isProtectGroup) {
		return null
	}

	return (
		<div
			className={classNames('absolute -bottom-6 hidden px-2 py-1 right-10',
				'rounded-b-lg',
				'text-white uppercase',
				'cursor-pointer',
				'lg:block lg:right-10"', {
					'bg-pulse-red': dashboardRestrictions === dashboardRestriction.Leadership,
					'bg-pulse-blue': dashboardRestrictions === dashboardRestriction.External,
					'bg-pulse-green': dashboardRestrictions === dashboardRestriction.Internal
				})}
			onClick={handleRestriction}
		>
			{dashboardRestrictionName[dashboardRestrictions]}
		</div>
	)
}

export default DashboardHeader
