import React from 'react'
import {useRecoilState} from 'recoil'
import {selectedCoreCurrencyState} from '../../../../../state/pulse-filters'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'
import {extendedCoreCurrencyDropdownData} from '../../../../../utils/data'

const CurrencyFilter = () => {
	const [currency, setCurrency] = useRecoilState(selectedCoreCurrencyState)

	return (
		<Listbox value={currency} onChange={setCurrency}>
			<ListboxButton>
				<div
					className="
						mb-2 pl-3 pr-2 py-[2px]
						bg-white
						border border-pulse-icon-gray rounded-full
						text-sm text-nowrap tracking-[1px] uppercase
						cursor-pointer
						hover:bg-pulse-button-blue
						lg:mb-0"
				>
					<span className="
						align-middle mr-1
						leading-5 text-base text-pulse-icon-gray
						material-symbols-outlined"
					>
						paid
					</span>
					<span className="align-middle leading-5">{currency}</span>
					<span className="
						align-middle
						leading-5
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				className="
					h-full min-w-[var(--button-width)] mt-[5px] py-2 z-50
					bg-white
					text-sm tracking-[1px] uppercase
					border border-pulse-popup-border-gray rounded-lg
					[--anchor-max-height:12rem]"
				anchor="bottom end"
			>
				{extendedCoreCurrencyDropdownData
					.sort((a, b) => a.value.localeCompare(b.value))
					.map((coreCurrency) => (
						<ListboxOption
							key={coreCurrency.id}
							value={coreCurrency.value}
							className="
								px-6 py-1
								cursor-pointer
								data-[focus]:bg-pulse-button-blue"
						>
							{coreCurrency.value}
							<span className="mb-1 text-pulse-icon-gray text-xs"> [{coreCurrency.name}]</span>
						</ListboxOption>
					))}
			</ListboxOptions>
		</Listbox>
	)
}

export default CurrencyFilter
