import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {partnersRevenueTableFilterState} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import {CardColumn, CardHeader, TableColumn, TableHeader} from './Common/TableElements'
import {Currency, Percentage} from './Common/FormatElements'
import PricingModelTag from './Common/Partners/PricingModelTag'
import PartnersPager from './Common/Partners/PartnersPager'
import PartnersSortColumnDropdown from './Common/Partners/PartnersSortColumnDropdown'
import PartnersPricingModelDropdown from './Common/Partners/PartnersPricingModelDropdown'
import {dashboardRestriction} from '../../../types/pulse'

const PartnersTable = () => {
	const {getPartnersRevenueTableData} = usePulseApi()
	const tableValue = useApiValue(partnersRevenueTableFilterState, getPartnersRevenueTableData, {
		members: []
	})

	return (
		<>
			<LoadingSpinner
				isLoading={tableValue.isLoading}
				className="flex items-center justify-center min-h-60 w-full"
			>
				<>
					<div className="flex my-4 space-x-4">
						<PartnersSortColumnDropdown/>
						<PartnersPricingModelDropdown/>
					</div>
					<div className="
						w-full
						text-sm
						lg:hidden"
					>
						{tableValue.data.members && tableValue.data.members
							.map((row, index) => (
								<div
									className="flex items-center mb-4 space-x-4"
									key={index}
								>
									<div className="
										gap-3 grid grid-cols-[175px_auto] pb-4 w-full
										border-b border-gray-200"
									>
										<CardHeader
											title="Partner"
										/>
										<CardColumn
											value=
												{
													<>
														{row.pricingModel && (<PricingModelTag tag={row.pricingModel}/>)}
														<span className="align-middle mr-3">{row.memberName}</span>
													</>
												}
										/>
										<CardHeader
											className="text-pulse-blue"
											title="PG Revenue"
										/>
										<CardColumn
											className="text-pulse-blue"
											value={<Currency value={row.protectionValue}/>}
										/>
										<CardHeader
											title="RP Sales"
										/>
										<CardColumn
											value={<Currency value={row.refundProtectValue}/>}
										/>
										<CardHeader
											title="Partner's Revenue"
										/>
										<CardColumn
											value={<Currency value={row.profitValue}/>}
										/>
										<CardHeader
											className="text-pulse-red"
											dashboardRestriction={dashboardRestriction.Leadership}
											title="Paid Refunds"
										/>
										<CardColumn
											className="text-pulse-red"
											dashboardRestriction={dashboardRestriction.Leadership}
											value={<Currency value={row.approvedRefundsValue}/>}
										/>
										<CardHeader
											className="text-pulse-orange"
											dashboardRestriction={dashboardRestriction.Leadership}
											title="Loss Ratio"
										/>
										<CardColumn
											className="text-pulse-orange"
											dashboardRestriction={dashboardRestriction.Leadership}
											value={<Percentage value={row.lossRatio}/>}
										/>
									</div>
								</div>
						))}
					</div>
					<table className="
						hidden mb-4 table-fixed w-full
						text-sm
						lg:table"
					>
						<thead>
						<tr className="
							mb-2
							border-b border-gray-200"
						>
							<TableHeader
								title="Partner"
							/>
							<TableHeader
								className="text-pulse-blue w-[14%]"
								title="PG Revenue"
							/>
							<TableHeader
								className="w-[14%]"
								title="RP Sales"
							/>
							<TableHeader
								className="w-[14%]"
								title="Partner's Revenue"
							/>
							<TableHeader
								className="text-pulse-red w-[14%]"
								dashboardRestriction={dashboardRestriction.Leadership}
								title="Paid Refunds"
							/>
							<TableHeader
								className="text-pulse-orange w-[14%]"
								dashboardRestriction={dashboardRestriction.Leadership}
								title="Loss Ratio"
							/>
						</tr>
						</thead>
						<tbody>
						{tableValue.data.members && tableValue.data.members
							.map((row, index) => (
								<tr
									key={index}
									className="border-b border-gray-200"
								>
									<TableColumn
										value={
											<>
												{row.pricingModel && (<PricingModelTag tag={row.pricingModel}/>)}
												<span className="align-middle mr-3">{row.memberName}</span>
											</>
										}
									/>
									<TableColumn
										className="text-pulse-blue"
										value={<Currency value={row.protectionValue}/>}
									/>
									<TableColumn
										value={<Currency value={row.refundProtectValue}/>}
									/>
									<TableColumn
										value={<Currency value={row.profitValue}/>}
									/>
									<TableColumn
										className="text-pulse-red"
										dashboardRestriction={dashboardRestriction.Leadership}
										value={<Currency value={row.approvedRefundsValue}/>}
									/>
									<TableColumn
										className="text-pulse-orange"
										dashboardRestriction={dashboardRestriction.Leadership}
										value={<Percentage value={row.lossRatio}/>}
									/>
								</tr>
						))}
						</tbody>
					</table>
					<div className="flex mb-4 ml-2 space-x-8">
						<div>
							<span className="
								align-middle h-3 inline-block mr-2 w-3
								bg-pulse-icon-gray opacity-60 rounded-full"
							/>
							<span className="
								align-middle
								text-sm"
							>
								Static
							</span>
						</div>
						<div>
							<span className="
								align-middle h-3 inline-block mr-2 w-3
								bg-pulse-green rounded-full"
							/>
							<span className="
								align-middle
								text-sm"
							>
								Dynamic
							</span>
						</div>
					</div>
				</>
			</LoadingSpinner>
			<PartnersPager data={tableValue.data}/>
		</>
	)
}

export default PartnersTable
