import React from 'react'
import classNames from 'classnames'

const H3 = (props: {
	children: React.ReactNode,
	className?: string
}) => {
	return (
		<h3 className={classNames('mb-2',
			'font-semibold text-base uppercase',
			props.className)}
		>
			{props.children}
		</h3>
	)
}

export default H3
