import {usePulseApi} from '../../../hooks/api/pulse'
import {partnersRevenueTableFilterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {
	ExportData,
	protectionPeriodName
} from '../../../types/pulse-data'
import {useExportData} from './useExportData'

export default function useExportProtectionPeriodBandData(): ExportData {
	const {
		getProtectionPeriodBandsChartData
	} = usePulseApi()
	const filter = useRecoilValue(partnersRevenueTableFilterState)

	const getData = async (): Promise<any> => {
		const result = await getProtectionPeriodBandsChartData({
			...filter,
			pageSize: 0
		})
		const data = []

		if (!result.chartData) {
			return []
		}

		for (let i = 0; i < result.chartData.length; i++) {
			const row = result.chartData[i]

			data.push({
				'Band': protectionPeriodName[row.protectionPeriod || 0],
				'Conversion Rate': `${row.conversionRate}%`,
				'Gross Booking Volume': row.grossBookingVolume
			})
		}

		return data
	}
	const getFileName = () => `ProtectionPeriodBands-${moment().toISOString()}`
	const getHeaders = () => [
		'Band',
		'Conversion Rate',
		'Gross Booking Volume'
	]

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
