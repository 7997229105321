import {usePulseApi} from '../../../hooks/api/pulse'
import {partnersRevenueTableFilterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {
	ExportData,
	refundPeriodName
} from '../../../types/pulse-data'
import {useExportData} from './useExportData'

export default function useExportRefundPeriodBandData(): ExportData {
	const {
		getRefundPeriodBandsChartData
	} = usePulseApi()
	const filter = useRecoilValue(partnersRevenueTableFilterState)

	const getData = async (): Promise<any> => {
		const result = await getRefundPeriodBandsChartData({
			...filter,
			pageSize: 0
		})
		const data = []

		if (!result.chartData) {
			return []
		}

		for (let i = 0; i < result.chartData.length; i++) {
			const row = result.chartData[i]

			data.push({
				'Band': refundPeriodName[row.refundPeriod || 0],
				'Volume': row.refundVolume
			})
		}

		return data
	}
	const getFileName = () => `RefundPeriodBands-${moment().toISOString()}`
	const getHeaders = () => [
		'Band',
		'Volume'
	]

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
